import { default as aboutxIRS5Bg1mXMeta } from "/buddy/pipeline-fs/pages/about.vue?macro=true";
import { default as allaccessu8zGuJI9EKMeta } from "/buddy/pipeline-fs/pages/allaccess.vue?macro=true";
import { default as apioKfDWekqFhMeta } from "/buddy/pipeline-fs/pages/api.vue?macro=true";
import { default as appLZpOciYXZuMeta } from "/buddy/pipeline-fs/pages/app.vue?macro=true";
import { default as indexKWHGcg7dipMeta } from "/buddy/pipeline-fs/pages/avalanche/[slug]/index.vue?macro=true";
import { default as buy3tdfXU0RcTMeta } from "/buddy/pipeline-fs/pages/buy.vue?macro=true";
import { default as careersJAezynu515Meta } from "/buddy/pipeline-fs/pages/careers.vue?macro=true";
import { default as contactZucHUnHjzOMeta } from "/buddy/pipeline-fs/pages/contact.vue?macro=true";
import { default as indexseUQXZf7IPMeta } from "/buddy/pipeline-fs/pages/dailysnow/index.vue?macro=true";
import { default as _91postId_93b9G5rg74e5Meta } from "/buddy/pipeline-fs/pages/dailysnow/post/[postId].vue?macro=true";
import { default as all_45camsvlbjNnPcOsMeta } from "/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/all-cams.vue?macro=true";
import { default as indexJZdDAhVyxNMeta } from "/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/index.vue?macro=true";
import { default as mapC3VW67ZcRgMeta } from "/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/map.vue?macro=true";
import { default as snow_45forecastbqdgR5AD8VMeta } from "/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/snow-forecast.vue?macro=true";
import { default as snow_45history1Gk4gXxjN8Meta } from "/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/snow-history.vue?macro=true";
import { default as snow_45reportsuP4lBFczZMeta } from "/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/snow-report.vue?macro=true";
import { default as snow_45summarySzqNssbtOWMeta } from "/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/snow-summary.vue?macro=true";
import { default as temperatureFWeW1BM9SKMeta } from "/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/temperature.vue?macro=true";
import { default as weather5fTevN7JtsMeta } from "/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/weather.vue?macro=true";
import { default as indexIvCmCPYPkqMeta } from "/buddy/pipeline-fs/pages/explore/index.vue?macro=true";
import { default as indexerosbRPyKLMeta } from "/buddy/pipeline-fs/pages/explore/powder/index.vue?macro=true";
import { default as mapJFVZ3ISrMtMeta } from "/buddy/pipeline-fs/pages/explore/powder/map.vue?macro=true";
import { default as snowstakesJNkO6toEIIMeta } from "/buddy/pipeline-fs/pages/explore/snowstakes.vue?macro=true";
import { default as indexVEr9aZIHdyMeta } from "/buddy/pipeline-fs/pages/index.vue?macro=true";
import { default as avalanche_45forecastkHVzjPrzaUMeta } from "/buddy/pipeline-fs/pages/location/[slug]/avalanche-forecast.vue?macro=true";
import { default as camsXP95R97yEmMeta } from "/buddy/pipeline-fs/pages/location/[slug]/cams.vue?macro=true";
import { default as daily_45snows3370yRhUrOMeta } from "/buddy/pipeline-fs/pages/location/[slug]/daily-snows.vue?macro=true";
import { default as indexnGdQMrFjWlMeta } from "/buddy/pipeline-fs/pages/location/[slug]/index.vue?macro=true";
import { default as infoAMKSMr0SLyMeta } from "/buddy/pipeline-fs/pages/location/[slug]/info.vue?macro=true";
import { default as snow_45reportcMWhhXFQWOMeta } from "/buddy/pipeline-fs/pages/location/[slug]/snow-report.vue?macro=true";
import { default as snow_45summarylqdGwSl5D2Meta } from "/buddy/pipeline-fs/pages/location/[slug]/snow-summary.vue?macro=true";
import { default as trail_45mapsdfzKFNnwB5Meta } from "/buddy/pipeline-fs/pages/location/[slug]/trail-maps.vue?macro=true";
import { default as weather_45stationsA6OKAlRupsMeta } from "/buddy/pipeline-fs/pages/location/[slug]/weather-stations.vue?macro=true";
import { default as weatheraMWwzrQQI7Meta } from "/buddy/pipeline-fs/pages/location/[slug]/weather.vue?macro=true";
import { default as map18GLMDN5IqMeta } from "/buddy/pipeline-fs/pages/map.vue?macro=true";
import { default as my_45locationfp0M38SljnMeta } from "/buddy/pipeline-fs/pages/my-location.vue?macro=true";
import { default as _91category_93axYO0EQ3G4Meta } from "/buddy/pipeline-fs/pages/news/[category].vue?macro=true";
import { default as indexLWWf7Z2GiZMeta } from "/buddy/pipeline-fs/pages/news/index.vue?macro=true";
import { default as _91idOrSlug_934hS0WQx41bMeta } from "/buddy/pipeline-fs/pages/news/post/[idOrSlug].vue?macro=true";
import { default as partnershipsy54PdASnfQMeta } from "/buddy/pipeline-fs/pages/partnerships.vue?macro=true";
import { default as privacyLKEtLAwjr8Meta } from "/buddy/pipeline-fs/pages/privacy.vue?macro=true";
import { default as storiesGTxMm7DjrBMeta } from "/buddy/pipeline-fs/pages/stories.vue?macro=true";
import { default as team1kuMI2e3GhMeta } from "/buddy/pipeline-fs/pages/team.vue?macro=true";
import { default as termsIoFGXRJgfAMeta } from "/buddy/pipeline-fs/pages/terms.vue?macro=true";
import { default as cams9SRLfmRFbZMeta } from "/buddy/pipeline-fs/pages/user/favorites/cams.vue?macro=true";
import { default as daily_45snows5jycoSXQhXMeta } from "/buddy/pipeline-fs/pages/user/favorites/daily-snows.vue?macro=true";
import { default as all_45camszEYDYwpLZkMeta } from "/buddy/pipeline-fs/pages/user/favorites/locations/all-cams.vue?macro=true";
import { default as indexLeke6xxGj1Meta } from "/buddy/pipeline-fs/pages/user/favorites/locations/index.vue?macro=true";
import { default as snow_45forecastBpxx955zUvMeta } from "/buddy/pipeline-fs/pages/user/favorites/locations/snow-forecast.vue?macro=true";
import { default as snow_45historyVeiS6BO2H0Meta } from "/buddy/pipeline-fs/pages/user/favorites/locations/snow-history.vue?macro=true";
import { default as snow_45reportxGS3M75tVBMeta } from "/buddy/pipeline-fs/pages/user/favorites/locations/snow-report.vue?macro=true";
import { default as snow_45summaryq61m3K3p2PMeta } from "/buddy/pipeline-fs/pages/user/favorites/locations/snow-summary.vue?macro=true";
import { default as temperatureLPDX8kqHzYMeta } from "/buddy/pipeline-fs/pages/user/favorites/locations/temperature.vue?macro=true";
import { default as weathern6CQ1MlAZ0Meta } from "/buddy/pipeline-fs/pages/user/favorites/locations/weather.vue?macro=true";
import { default as mapmRW9zHPcjfMeta } from "/buddy/pipeline-fs/pages/user/favorites/map.vue?macro=true";
import { default as season_45passesNa12kHQqrsMeta } from "/buddy/pipeline-fs/pages/user/favorites/season-passes.vue?macro=true";
import { default as trail_45mapsOj6i7Sbj6EMeta } from "/buddy/pipeline-fs/pages/user/favorites/trail-maps.vue?macro=true";
import { default as forgotpasswordIpW8UQHqwfMeta } from "/buddy/pipeline-fs/pages/user/forgotpassword.vue?macro=true";
import { default as verifyaW3MtqQodEMeta } from "/buddy/pipeline-fs/pages/user/group/join/verify.vue?macro=true";
import { default as indexfkZmk1cUtcMeta } from "/buddy/pipeline-fs/pages/user/login/index.vue?macro=true";
import { default as verifykKYKLNNdNdMeta } from "/buddy/pipeline-fs/pages/user/login/verify.vue?macro=true";
import { default as indexbRvrsGhnrMMeta } from "/buddy/pipeline-fs/pages/user/register/index.vue?macro=true";
import { default as verifyZnA4aOJfL8Meta } from "/buddy/pipeline-fs/pages/user/register/verify.vue?macro=true";
import { default as indexwXG6wFO6O1Meta } from "/buddy/pipeline-fs/pages/user/settings/account/index.vue?macro=true";
import { default as changeemail3WjXhBxA7qMeta } from "/buddy/pipeline-fs/pages/user/settings/changeemail.vue?macro=true";
import { default as changepasswordziU5CcztJlMeta } from "/buddy/pipeline-fs/pages/user/settings/changepassword.vue?macro=true";
import { default as indexsJMvjeaNUdMeta } from "/buddy/pipeline-fs/pages/user/settings/delete/index.vue?macro=true";
import { default as verifySf98aLKcj6Meta } from "/buddy/pipeline-fs/pages/user/settings/delete/verify.vue?macro=true";
import { default as verifyDIHMym0S0IMeta } from "/buddy/pipeline-fs/pages/user/settings/email/verify.vue?macro=true";
import { default as index86zTFy0DJ6Meta } from "/buddy/pipeline-fs/pages/user/settings/favorites/index.vue?macro=true";
import { default as indexRndulMSs17Meta } from "/buddy/pipeline-fs/pages/user/settings/group/index.vue?macro=true";
import { default as leave7HgzdBrr4JMeta } from "/buddy/pipeline-fs/pages/user/settings/group/leave.vue?macro=true";
import { default as indexzWo5ir8Di7Meta } from "/buddy/pipeline-fs/pages/user/settings/notifications/index.vue?macro=true";
import { default as verifyggCCA5SoOMMeta } from "/buddy/pipeline-fs/pages/user/settings/password/verify.vue?macro=true";
import { default as privacyt3xbyt3rkGMeta } from "/buddy/pipeline-fs/pages/user/settings/privacy.vue?macro=true";
import { default as start_45trialAkbBaTDLnEMeta } from "/buddy/pipeline-fs/pages/user/settings/start-trial.vue?macro=true";
import { default as indexSUnYHqko2hMeta } from "/buddy/pipeline-fs/pages/weather-stations/[slug]/index.vue?macro=true";
import { default as _91postId_93Xn95gHJvp4Meta } from "~/pages/dailysnow/post/[postId].vue?macro=true";
import { default as mapQ81q6fQcVWMeta } from "~/pages/explore/[compareType]/[compareId]/map.vue?macro=true";
import { default as mapQsnOQhIR5BMeta } from "~/pages/map.vue?macro=true";
import { default as buyWhjNBaSCMcMeta } from "~/pages/buy?macro=true";
import { default as accountEEhexOxKZCMeta } from "~/pages/user/settings/account?macro=true";
import { default as camssIXCdVvgOZMeta } from "~/pages/user/favorites/cams?macro=true";
import { default as indexh8dBLeJKEOMeta } from "~/pages/user/favorites/locations/index?macro=true";
import { default as component_45stubCgj2AAuPTBMeta } from "/buddy/pipeline-fs/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubCgj2AAuPTB } from "/buddy/pipeline-fs/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/buddy/pipeline-fs/pages/about.vue")
  },
  {
    name: "allaccess",
    path: "/allaccess",
    component: () => import("/buddy/pipeline-fs/pages/allaccess.vue")
  },
  {
    name: "api",
    path: "/api",
    component: () => import("/buddy/pipeline-fs/pages/api.vue")
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/buddy/pipeline-fs/pages/app.vue")
  },
  {
    name: "avalanche-slug",
    path: "/avalanche/:slug()",
    component: () => import("/buddy/pipeline-fs/pages/avalanche/[slug]/index.vue")
  },
  {
    name: "buy",
    path: "/buy",
    meta: buy3tdfXU0RcTMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/buy.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/buddy/pipeline-fs/pages/careers.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/buddy/pipeline-fs/pages/contact.vue")
  },
  {
    name: "dailysnow",
    path: "/dailysnow",
    component: () => import("/buddy/pipeline-fs/pages/dailysnow/index.vue")
  },
  {
    name: "dailysnow-post-postId",
    path: "/dailysnow/post/:postId()",
    component: () => import("/buddy/pipeline-fs/pages/dailysnow/post/[postId].vue")
  },
  {
    name: "explore-compareType-compareId-all-cams",
    path: "/explore/:compareType()/:compareId()/all-cams",
    component: () => import("/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/all-cams.vue")
  },
  {
    name: "explore-compareType-compareId",
    path: "/explore/:compareType()/:compareId()",
    meta: indexJZdDAhVyxNMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/index.vue")
  },
  {
    name: "explore-compareType-compareId-map",
    path: "/explore/:compareType()/:compareId()/map",
    component: () => import("/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/map.vue")
  },
  {
    name: "explore-compareType-compareId-snow-forecast",
    path: "/explore/:compareType()/:compareId()/snow-forecast",
    component: () => import("/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/snow-forecast.vue")
  },
  {
    name: "explore-compareType-compareId-snow-history",
    path: "/explore/:compareType()/:compareId()/snow-history",
    component: () => import("/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/snow-history.vue")
  },
  {
    name: "explore-compareType-compareId-snow-report",
    path: "/explore/:compareType()/:compareId()/snow-report",
    component: () => import("/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/snow-report.vue")
  },
  {
    name: "explore-compareType-compareId-snow-summary",
    path: "/explore/:compareType()/:compareId()/snow-summary",
    component: () => import("/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/snow-summary.vue")
  },
  {
    name: "explore-compareType-compareId-temperature",
    path: "/explore/:compareType()/:compareId()/temperature",
    component: () => import("/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/temperature.vue")
  },
  {
    name: "explore-compareType-compareId-weather",
    path: "/explore/:compareType()/:compareId()/weather",
    component: () => import("/buddy/pipeline-fs/pages/explore/[compareType]/[compareId]/weather.vue")
  },
  {
    name: "explore",
    path: "/explore",
    component: () => import("/buddy/pipeline-fs/pages/explore/index.vue")
  },
  {
    name: "explore-powder",
    path: "/explore/powder",
    component: () => import("/buddy/pipeline-fs/pages/explore/powder/index.vue")
  },
  {
    name: "explore-powder-map",
    path: "/explore/powder/map",
    component: () => import("/buddy/pipeline-fs/pages/explore/powder/map.vue")
  },
  {
    name: "explore-snowstakes",
    path: "/explore/snowstakes",
    component: () => import("/buddy/pipeline-fs/pages/explore/snowstakes.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/buddy/pipeline-fs/pages/index.vue")
  },
  {
    name: "location-slug-avalanche-forecast",
    path: "/location/:slug()/avalanche-forecast",
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/avalanche-forecast.vue")
  },
  {
    name: "location-slug-cams",
    path: "/location/:slug()/cams",
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/cams.vue")
  },
  {
    name: "location-slug-daily-snows",
    path: "/location/:slug()/daily-snows",
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/daily-snows.vue")
  },
  {
    name: "location-slug",
    path: "/location/:slug()",
    meta: indexnGdQMrFjWlMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/index.vue")
  },
  {
    name: "location-slug-info",
    path: "/location/:slug()/info",
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/info.vue")
  },
  {
    name: "location-slug-snow-report",
    path: "/location/:slug()/snow-report",
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/snow-report.vue")
  },
  {
    name: "location-slug-snow-summary",
    path: "/location/:slug()/snow-summary",
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/snow-summary.vue")
  },
  {
    name: "location-slug-trail-maps",
    path: "/location/:slug()/trail-maps",
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/trail-maps.vue")
  },
  {
    name: "location-slug-weather-stations",
    path: "/location/:slug()/weather-stations",
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/weather-stations.vue")
  },
  {
    name: "location-slug-weather",
    path: "/location/:slug()/weather",
    component: () => import("/buddy/pipeline-fs/pages/location/[slug]/weather.vue")
  },
  {
    name: "map",
    path: "/map",
    component: () => import("/buddy/pipeline-fs/pages/map.vue")
  },
  {
    name: "my-location",
    path: "/my-location",
    meta: my_45locationfp0M38SljnMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/my-location.vue")
  },
  {
    name: "news-category",
    path: "/news/:category()",
    component: () => import("/buddy/pipeline-fs/pages/news/[category].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/buddy/pipeline-fs/pages/news/index.vue")
  },
  {
    name: "news-post-idOrSlug",
    path: "/news/post/:idOrSlug()",
    component: () => import("/buddy/pipeline-fs/pages/news/post/[idOrSlug].vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/buddy/pipeline-fs/pages/partnerships.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/buddy/pipeline-fs/pages/privacy.vue")
  },
  {
    name: "stories",
    path: "/stories",
    meta: storiesGTxMm7DjrBMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/stories.vue")
  },
  {
    name: "team",
    path: "/team",
    component: () => import("/buddy/pipeline-fs/pages/team.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/buddy/pipeline-fs/pages/terms.vue")
  },
  {
    name: "user-favorites-cams",
    path: "/user/favorites/cams",
    meta: cams9SRLfmRFbZMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/cams.vue")
  },
  {
    name: "user-favorites-daily-snows",
    path: "/user/favorites/daily-snows",
    meta: daily_45snows5jycoSXQhXMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/daily-snows.vue")
  },
  {
    name: "user-favorites-locations-all-cams",
    path: "/user/favorites/locations/all-cams",
    meta: all_45camszEYDYwpLZkMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/locations/all-cams.vue")
  },
  {
    name: "user-favorites-locations",
    path: "/user/favorites/locations",
    meta: indexLeke6xxGj1Meta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/locations/index.vue")
  },
  {
    name: "user-favorites-locations-snow-forecast",
    path: "/user/favorites/locations/snow-forecast",
    meta: snow_45forecastBpxx955zUvMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/locations/snow-forecast.vue")
  },
  {
    name: "user-favorites-locations-snow-history",
    path: "/user/favorites/locations/snow-history",
    meta: snow_45historyVeiS6BO2H0Meta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/locations/snow-history.vue")
  },
  {
    name: "user-favorites-locations-snow-report",
    path: "/user/favorites/locations/snow-report",
    meta: snow_45reportxGS3M75tVBMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/locations/snow-report.vue")
  },
  {
    name: "user-favorites-locations-snow-summary",
    path: "/user/favorites/locations/snow-summary",
    meta: snow_45summaryq61m3K3p2PMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/locations/snow-summary.vue")
  },
  {
    name: "user-favorites-locations-temperature",
    path: "/user/favorites/locations/temperature",
    meta: temperatureLPDX8kqHzYMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/locations/temperature.vue")
  },
  {
    name: "user-favorites-locations-weather",
    path: "/user/favorites/locations/weather",
    meta: weathern6CQ1MlAZ0Meta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/locations/weather.vue")
  },
  {
    name: "user-favorites-map",
    path: "/user/favorites/map",
    meta: mapmRW9zHPcjfMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/map.vue")
  },
  {
    name: "user-favorites-season-passes",
    path: "/user/favorites/season-passes",
    meta: season_45passesNa12kHQqrsMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/season-passes.vue")
  },
  {
    name: "user-favorites-trail-maps",
    path: "/user/favorites/trail-maps",
    meta: trail_45mapsOj6i7Sbj6EMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/favorites/trail-maps.vue")
  },
  {
    name: "user-forgotpassword",
    path: "/user/forgotpassword",
    meta: forgotpasswordIpW8UQHqwfMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/forgotpassword.vue")
  },
  {
    name: "user-group-join-verify",
    path: "/user/group/join/verify",
    meta: verifyaW3MtqQodEMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/group/join/verify.vue")
  },
  {
    name: "user-login",
    path: "/user/login",
    meta: indexfkZmk1cUtcMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/login/index.vue")
  },
  {
    name: "user-login-verify",
    path: "/user/login/verify",
    meta: verifykKYKLNNdNdMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/login/verify.vue")
  },
  {
    name: "user-register",
    path: "/user/register",
    meta: indexbRvrsGhnrMMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/register/index.vue")
  },
  {
    name: "user-register-verify",
    path: "/user/register/verify",
    meta: verifyZnA4aOJfL8Meta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/register/verify.vue")
  },
  {
    name: "user-settings-account",
    path: "/user/settings/account",
    meta: indexwXG6wFO6O1Meta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/settings/account/index.vue")
  },
  {
    name: "user-settings-changeemail",
    path: "/user/settings/changeemail",
    meta: changeemail3WjXhBxA7qMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/settings/changeemail.vue")
  },
  {
    name: "user-settings-changepassword",
    path: "/user/settings/changepassword",
    meta: changepasswordziU5CcztJlMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/settings/changepassword.vue")
  },
  {
    name: "user-settings-delete",
    path: "/user/settings/delete",
    meta: indexsJMvjeaNUdMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/settings/delete/index.vue")
  },
  {
    name: "user-settings-delete-verify",
    path: "/user/settings/delete/verify",
    meta: verifySf98aLKcj6Meta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/settings/delete/verify.vue")
  },
  {
    name: "user-settings-email-verify",
    path: "/user/settings/email/verify",
    meta: verifyDIHMym0S0IMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/settings/email/verify.vue")
  },
  {
    name: "user-settings-favorites",
    path: "/user/settings/favorites",
    meta: index86zTFy0DJ6Meta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/settings/favorites/index.vue")
  },
  {
    name: "user-settings-group",
    path: "/user/settings/group",
    meta: indexRndulMSs17Meta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/settings/group/index.vue")
  },
  {
    name: "user-settings-group-leave",
    path: "/user/settings/group/leave",
    component: () => import("/buddy/pipeline-fs/pages/user/settings/group/leave.vue")
  },
  {
    name: "user-settings-notifications",
    path: "/user/settings/notifications",
    meta: indexzWo5ir8Di7Meta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/settings/notifications/index.vue")
  },
  {
    name: "user-settings-password-verify",
    path: "/user/settings/password/verify",
    meta: verifyggCCA5SoOMMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/settings/password/verify.vue")
  },
  {
    name: "user-settings-privacy",
    path: "/user/settings/privacy",
    meta: privacyt3xbyt3rkGMeta || {},
    component: () => import("/buddy/pipeline-fs/pages/user/settings/privacy.vue")
  },
  {
    name: "user-settings-start-trial",
    path: "/user/settings/start-trial",
    component: () => import("/buddy/pipeline-fs/pages/user/settings/start-trial.vue")
  },
  {
    name: "weather-stations-slug",
    path: "/weather-stations/:slug()",
    component: () => import("/buddy/pipeline-fs/pages/weather-stations/[slug]/index.vue")
  },
  {
    name: "dailysnow-slug-post-id",
    path: "/dailysnow/:slug/post/:postId",
    component: () => import("~/pages/dailysnow/post/[postId].vue")
  },
  {
    name: "dailysnow-slug",
    path: "/dailysnow/:slug",
    component: () => import("~/pages/dailysnow/post/[postId].vue")
  },
  {
    name: "explore-type-id-map-latlngzoom",
    path: "/explore/:compareType/:compareId/map/:latlngzoom",
    component: () => import("~/pages/explore/[compareType]/[compareId]/map.vue")
  },
  {
    name: "map-basemap",
    path: "/map/:basemap",
    component: () => import("~/pages/map.vue")
  },
  {
    name: "map-basemap-overlay",
    path: "/map/:basemap/:overlay",
    component: () => import("~/pages/map.vue")
  },
  {
    name: "map-basemap-overlay-latlngzoom",
    path: "/map/:basemap/:overlay/:latlngzoom",
    component: () => import("~/pages/map.vue")
  },
  {
    name: "user-buy-now",
    path: "/user/buy-now",
    component: () => import("~/pages/buy")
  },
  {
    name: "user-settings",
    path: "/user/settings",
    component: () => import("~/pages/user/settings/account")
  },
  {
    name: "user-settings-profile",
    path: "/user/settings/profile",
    component: () => import("~/pages/user/settings/account")
  },
  {
    name: "user-settings-subscription",
    path: "/user/settings/subscription",
    component: () => import("~/pages/user/settings/account")
  },
  {
    name: "user-settings-upgrade",
    path: "/user/settings/upgrade",
    component: () => import("~/pages/buy")
  },
  {
    name: "user-favorites-cam-id",
    path: "/user/favorites/cam/:id",
    component: () => import("~/pages/user/favorites/cams")
  },
  {
    name: "user-favorites",
    path: "/user/favorites",
    component: () => import("~/pages/user/favorites/locations/index")
  },
  {
    name: "weblink-about",
    path: "/weblink/about",
    component: () => import("/buddy/pipeline-fs/pages/about.vue")
  },
  {
    name: "weblink-allaccess",
    path: "/weblink/allaccess",
    component: () => import("/buddy/pipeline-fs/pages/allaccess.vue")
  },
  {
    name: "weblink-app",
    path: "/weblink/app",
    component: () => import("/buddy/pipeline-fs/pages/app.vue")
  },
  {
    name: "weblink-buy",
    path: "/weblink/buy",
    component: () => import("/buddy/pipeline-fs/pages/buy.vue")
  },
  {
    name: "weblink-careers",
    path: "/weblink/careers",
    component: () => import("/buddy/pipeline-fs/pages/careers.vue")
  },
  {
    name: "weblink-contact",
    path: "/weblink/contact",
    component: () => import("/buddy/pipeline-fs/pages/contact.vue")
  },
  {
    name: "weblink-partnerships",
    path: "/weblink/partnerships",
    component: () => import("/buddy/pipeline-fs/pages/partnerships.vue")
  },
  {
    name: "weblink-privacy",
    path: "/weblink/privacy",
    component: () => import("/buddy/pipeline-fs/pages/privacy.vue")
  },
  {
    name: "weblink-team",
    path: "/weblink/team",
    component: () => import("/buddy/pipeline-fs/pages/team.vue")
  },
  {
    name: "weblink-terms",
    path: "/weblink/terms",
    component: () => import("/buddy/pipeline-fs/pages/terms.vue")
  },
  {
    name: "weblink-user-group-join-verify",
    path: "/weblink/user/group/join/verify",
    component: () => import("/buddy/pipeline-fs/pages/user/group/join/verify.vue")
  },
  {
    name: "weblink-user-login",
    path: "/weblink/user/login",
    component: () => import("/buddy/pipeline-fs/pages/user/login/index.vue")
  },
  {
    name: "weblink-user-login-verify",
    path: "/weblink/user/login/verify",
    component: () => import("/buddy/pipeline-fs/pages/user/login/verify.vue")
  },
  {
    name: "weblink-user-register-verify",
    path: "/weblink/user/register/verify",
    component: () => import("/buddy/pipeline-fs/pages/user/register/verify.vue")
  },
  {
    name: "weblink-user-settings-account",
    path: "/weblink/user/settings/account",
    component: () => import("/buddy/pipeline-fs/pages/user/settings/account/index.vue")
  },
  {
    name: "weblink-user-settings-delete-verify",
    path: "/weblink/user/settings/delete/verify",
    component: () => import("/buddy/pipeline-fs/pages/user/settings/delete/verify.vue")
  },
  {
    name: "weblink-user-settings-email-verify",
    path: "/weblink/user/settings/email/verify",
    component: () => import("/buddy/pipeline-fs/pages/user/settings/email/verify.vue")
  },
  {
    name: "weblink-user-settings-group",
    path: "/weblink/user/settings/group",
    component: () => import("/buddy/pipeline-fs/pages/user/settings/group/index.vue")
  },
  {
    name: "weblink-user-settings-password-verify",
    path: "/weblink/user/settings/password/verify",
    component: () => import("/buddy/pipeline-fs/pages/user/settings/password/verify.vue")
  },
  {
    name: "weblink-user-buy-now",
    path: "/weblink/user/buy-now",
    component: () => import("~/pages/buy")
  },
  {
    name: "weblink-user-settings",
    path: "/weblink/user/settings",
    component: () => import("~/pages/user/settings/account")
  },
  {
    name: "weblink-user-settings-profile",
    path: "/weblink/user/settings/profile",
    component: () => import("~/pages/user/settings/account")
  },
  {
    name: "weblink-user-settings-subscription",
    path: "/weblink/user/settings/subscription",
    component: () => import("~/pages/user/settings/account")
  },
  {
    name: "weblink-user-settings-upgrade",
    path: "/weblink/user/settings/upgrade",
    component: () => import("~/pages/buy")
  },
  {
    name: component_45stubCgj2AAuPTBMeta?.name,
    path: "/user/favorites/locations/summary",
    component: component_45stubCgj2AAuPTB
  },
  {
    name: component_45stubCgj2AAuPTBMeta?.name,
    path: "/user/favorites/locations/10-day-forecast",
    component: component_45stubCgj2AAuPTB
  },
  {
    name: component_45stubCgj2AAuPTBMeta?.name,
    path: "/user/favorites/locations/10-day-history",
    component: component_45stubCgj2AAuPTB
  },
  {
    name: component_45stubCgj2AAuPTBMeta?.name,
    path: "/user/notifications/newsletters",
    component: component_45stubCgj2AAuPTB
  },
  {
    name: component_45stubCgj2AAuPTBMeta?.name,
    path: "/user/settings/alerts/dailysnow",
    component: component_45stubCgj2AAuPTB
  },
  {
    name: component_45stubCgj2AAuPTBMeta?.name,
    path: "/user/settings/alerts/snow",
    component: component_45stubCgj2AAuPTB
  },
  {
    name: component_45stubCgj2AAuPTBMeta?.name,
    path: "/user/settings/alerts",
    component: component_45stubCgj2AAuPTB
  }
]